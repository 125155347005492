var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.publicProfile ? _c('editable-page', {
    attrs: {
      "title": _vm.publicProfile && _vm.publicProfile.getHandle() ? _vm.publicProfile.getHandle() : _vm.name,
      "imageUrl": _vm.publicProfile.getPublicProfile() && _vm.publicProfile.getPublicProfile().getImageUrl(),
      "apiStatusList": [_vm.getPublicProfileStatus],
      "edit": false
    },
    scopedSlots: _vm._u([_vm.hasSubtitle ? {
      key: "subtitle",
      fn: function () {
        return [_c('v-row', {
          staticClass: "pl-5"
        }, [_c('v-col', [_c('stats-col', {
          staticClass: "font-weight-medium",
          attrs: {
            "title": "Joined",
            "cols": 6,
            "md": 3,
            "lg": 2
          }
        }, [_vm._v(" " + _vm._s(_vm.publicProfile && _vm.publicProfile.getJoinedOn() && _vm.formatDate(_vm.publicProfile.getJoinedOn())) + " ")])], 1), _c('v-col', [_c('stats-col', {
          staticClass: "font-weight-medium",
          attrs: {
            "title": "Problems Solved",
            "cols": 6,
            "md": 3,
            "lg": 2
          }
        }, [_vm._v(" " + _vm._s(_vm.publicProfile && _vm.publicProfile.getPublicProfile() && _vm.publicProfile.getPublicProfile().getProblemsSolved()) + " ")])], 1), _c('v-col', [_c('stats-col', {
          staticClass: "font-weight-medium",
          attrs: {
            "title": "Name",
            "cols": 6,
            "md": 3,
            "lg": 2
          }
        }, [_vm._v(" " + _vm._s(_vm.publicProfile && _vm.publicProfile.getName()) + " ")])], 1), _vm.handle === _vm.userHandle ? _c('v-col', [_c('stats-col', {
          attrs: {
            "title": ""
          }
        }, [_c('v-btn', {
          staticClass: "mb-1",
          attrs: {
            "color": "accent"
          },
          on: {
            "click": function ($event) {
              return _vm.profileEditMode();
            }
          }
        }, [_vm._v("Edit")])], 1)], 1) : _vm._e()], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('v-tabs', {
    attrs: {
      "color": "accent"
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.index
    }, [_vm._v(" " + _vm._s(tab.name) + " ")]);
  }), _c('v-tab-item', [_c('v-row', [_c('v-col', [_c('competitive-profile', {
    attrs: {
      "competitiveProfiles": _vm.publicProfile && _vm.publicProfile.getPublicProfile() && _vm.publicProfile.getPublicProfile().getCompetitiveProfilesList(),
      "edit": false,
      "isSelfProfile": _vm.handle === _vm.userHandle
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("Contests")]), _c('v-data-table', {
    staticClass: "px-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.publicProfile.getPublicProfile() && _vm.publicProfile.getPublicProfile().getContestStatsList()
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [item.getContentPreview() ? _c('router-link', {
          staticClass: "text-decoration-none router_link",
          attrs: {
            "to": '/contests/' + item.getContentPreview().getUrl()
          }
        }, [_vm._v(" " + _vm._s(item.getContentPreview().getTitle()) + " ")]) : _c('span', [_vm._v(" Unknown Contest ")])], 1), _c('td', [item.getContentPreview() ? _c('router-link', {
          staticClass: "text-decoration-none router_link",
          attrs: {
            "to": '/contests/' + item.getContentPreview().getUrl() + '/scoreboard'
          }
        }, [_vm._v(" " + _vm._s(item.getRank()) + " ")]) : _c('span', [_vm._v(" " + _vm._s(item.getRank()))])], 1), _c('td', [_vm._v(_vm._s(item.getProblemSolved()))])])];
      }
    }], null, false, 3019599990)
  })], 1)], 1)], 1)], 1)], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }